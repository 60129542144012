import type { SidebarPage, SidebarProps } from '.';
import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageDocument } from '@readme/backend/models/page/types';

import path from 'path';

import { getChildrenPages, isEmptyParentPage } from '@readme/iso';
import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import type { HTTPMethod } from '@core/types';

import Method from '@ui/API/Method';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

export interface SidebarListSharedProps {
  /**
   * Slug for the current page.
   */
  activeDoc?: string;
  activeItemRef: React.RefObject<HTMLAnchorElement>;
  alwaysExpanded: boolean;
  children?: React.ReactNode;

  /**
   * Optional custom icon render function for the sidebar list items.
   */
  customIconRender: SidebarProps['customIconRender'];
  linkClass?: string;

  /**
   * Sidebar link prefix.
   */
  pathRoot?: string;
}

interface SidebarListProps extends SidebarListSharedProps {
  className?: string;
  pages: SidebarPage[];
}

interface SidebarListItemProps extends SidebarListSharedProps {
  page: SidebarPage;
}

const hasChildren = (childrenPages: GitSidebarPage[] | PageDocument[]) =>
  (childrenPages || []).filter(child => !child.hidden).length;

const SidebarListItem = ({
  activeItemRef,
  activeDoc: reqSlug,
  alwaysExpanded = false,
  customIconRender,
  page,
  children,
  pathRoot,
  linkClass = '',
}: SidebarListItemProps) => {
  // Check presence of properties in page object to avoid type errors
  const icon = 'icon' in page ? page.icon : '';
  const isReference = 'isReference' in page ? page.isReference : false;
  const pageType = 'pageType' in page ? page.pageType : undefined;

  const { deprecated, link_external: linkExternal, link_url: linkUrl, slug: pageSlug, title, type } = page;
  const { slug: routeSlug } = useParams<{ slug?: string }>();
  const [isExpanded, setIsExpanded] = useState(alwaysExpanded);
  const [linkClasses, setLinkClasses] = useState(linkClass);

  // If the page is an empty parent page, let's link to the first child instead
  const isEmptyParent = isEmptyParentPage(page);
  const childrenPages = useMemo(() => getChildrenPages(page), [page]);
  const slugToLinkTo = isEmptyParent ? childrenPages[0].slug : pageSlug;
  const sendToChildLink = isEmptyParent && childrenPages[0].type === 'link';
  const isLinkType = type === 'link';
  const openLinkInNewTab = isLinkType && linkExternal;
  const linkPrefix = pathRoot || (isReference ? 'reference' : 'docs');

  let linkToValue;
  if (isLinkType) {
    linkToValue = linkUrl;
  } else if (sendToChildLink) {
    linkToValue = childrenPages[0].link_url;
  } else {
    linkToValue = path.join('/', linkPrefix, pageType === 'RealtimePage' ? 'intro' : '', slugToLinkTo);
  }

  const isActive = pageSlug === (routeSlug || reqSlug);

  useEffect(() => {
    const slugExistsInChildren = (child, targetSlug) => {
      if (!child || child?.length === 0) return false;

      return child.some(c => {
        if (c.slug === targetSlug) return true;

        // Recursive check
        return slugExistsInChildren(c.children, targetSlug);
      });
    };

    const hasChild = slugExistsInChildren(childrenPages, routeSlug || reqSlug);

    // Force-set an .active class on the first doc
    // when visiting the slug-less top-level route.
    if (!routeSlug && isActive) setLinkClasses(`${linkClass} active`);
    else setLinkClasses(linkClass);

    // Toggle subnav lists expansion.
    setIsExpanded(alwaysExpanded || (children && (isActive || hasChild)) || false);
  }, [alwaysExpanded, children, isActive, linkClass, childrenPages, pageSlug, reqSlug, routeSlug]);

  const Link =
    !isLinkType && !sendToChildLink
      ? NavLink
      : ({ to, ...props }) => (
          <a {...props} href={to}>
            {props.children}
          </a>
        );

  /**
   * Soft-toggle a list item group without
   * navigating to the parent page.
   */
  const softToggle = e => {
    e.preventDefault();
    if (!alwaysExpanded) setIsExpanded(!isExpanded);
  };

  const customIcon = useMemo(() => {
    if (typeof customIconRender === 'function') {
      const custom = customIconRender(pageType, title);

      if (custom) {
        return <div className={`${classes['Sidebar-link-pageIconCustom']}`}>{custom}</div>;
      }
    }

    return null;
  }, [customIconRender, pageType, title]);

  const pageIcon = useMemo(() => {
    if (customIcon) return customIcon;

    if (!icon?.length) return null;

    return <i className={`${classes['Sidebar-link-pageIcon']} ${icon}`} />;
  }, [customIcon, icon]);

  return (
    <li className={`${classes['Sidebar-item']}${isExpanded ? ' subnav-expanded' : ''}`}>
      <Link
        {...(isActive ? { ref: activeItemRef } : null)}
        className={`${classes['Sidebar-link']} ${children ? classes['Sidebar-link_parent'] : ''} ${
          deprecated ? classes['Sidebar-link_deprecated'] : ''
        } ${linkClasses} text-wrap rm-Sidebar-link`}
        // We should never set the `active` class on empty parent pages!
        isActive={match => (match && !isEmptyParent) || false}
        rel={openLinkInNewTab ? 'noopener' : undefined}
        target={openLinkInNewTab ? '_blank' : '_self'}
        to={linkToValue}
      >
        {!!deprecated && (
          <Tooltip
            content={`Deprecated ${page.type === 'endpoint' ? 'Endpoint' : 'Doc'}`}
            interactive={false}
            placement="bottom"
          >
            <span className={`icon-alert-circle ${classes['Sidebar-link_deprecated-icon']}`} />
          </Tooltip>
        )}
        <span className={classes['Sidebar-link-text']}>
          {!!children && (
            <button
              aria-expanded={isExpanded}
              aria-label={`${isExpanded ? 'Hide' : 'Show'} subpages for ${title}`}
              className={classes['Sidebar-link-buttonWrapper']}
              onClick={softToggle}
              onKeyDown={e => [' ', 'Enter'].includes(e.key) && softToggle(e)}
            >
              <i className={`${classes['Sidebar-link-expandIcon']} icon-chevron-rightward`} />
            </button>
          )}
          {pageIcon}
          <span>{title}</span>
        </span>
        {!!isLinkType && (
          <i aria-hidden="true" className={`${classes['Sidebar-link-externalIcon']} icon-arrow-up-right`} />
        )}
        {(page.type === 'endpoint' || page.type === 'webhook') &&
          'api' in page &&
          !!page?.api?.method &&
          page.pageType !== 'RealtimePage' && (
            <Method
              className={classes['Sidebar-method']}
              fixedWidth
              type={page.api.method.toLowerCase() as HTTPMethod}
            />
          )}
      </Link>
      {children}
    </li>
  );
};

const SidebarList = ({
  activeItemRef,
  alwaysExpanded = false,
  className = '',
  linkClass = '',
  pages,
  ...rest
}: SidebarListProps) => (
  /**
   * @todo: some of the destructuring herein is _very_ confusing...
   */
  /* ul styles here also moved to sidebar-list */
  <ul className={`${className} ${classes['Sidebar-list']} rm-Sidebar-list`}>
    {pages.map(page => {
      const childrenPages =
        ('children' in page && (page.children as PageDocument[])) || ('pages' in page && page.pages) || [];
      return (
        !page.hidden &&
        (hasChildren(childrenPages) ? (
          <SidebarListItem
            key={`page-${page.slug}`}
            activeItemRef={activeItemRef}
            alwaysExpanded={alwaysExpanded}
            page={page}
            {...rest}
          >
            <SidebarList
              activeItemRef={activeItemRef}
              alwaysExpanded={alwaysExpanded}
              className="subpages"
              pages={childrenPages}
              {...rest}
            />
          </SidebarListItem>
        ) : (
          <SidebarListItem
            key={`page-${page.slug}`}
            activeItemRef={activeItemRef}
            alwaysExpanded={alwaysExpanded}
            linkClass={`childless ${className === 'subpages' ? 'subpage' : linkClass}`}
            page={page}
            {...rest}
          />
        ))
      );
    })}
  </ul>
);

export default SidebarList;
