import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { CategoryClientSide } from '@readme/backend/models/category/types';
import type { PageClientSide } from '@readme/backend/models/page/types';

import { useContext } from 'react';

import type { AppMetaContextValue } from '@core/context';
import { AppMetaContext } from '@core/context';
import type { SuperHubDocumentData } from '@core/store';

function flattenPageList(pages: (GitSidebarPage | PageClientSide)[]) {
  const list: (GitSidebarPage | PageClientSide)[] = [];

  const recurseChildren = (data: (GitSidebarPage | PageClientSide)[]) => {
    data.forEach?.(item => {
      if (!item.hidden && ['basic', 'endpoint'].includes(item.type)) list.push(item);
      if ('children' in item && item.children) {
        recurseChildren(item.children);
      } else if ('pages' in item && item.pages) {
        recurseChildren(item.pages);
      }
    });
  };

  recurseChildren(pages);
  return list;
}

type PaginationPage<T extends PageClientSide | SuperHubDocumentData> = T extends PageClientSide
  ? PageClientSide
  : GitSidebarPage;

type PaginationSidebar<T extends PageClientSide | SuperHubDocumentData> = T extends PageClientSide
  ? CategoryClientSide
  : GitSidebarCategory;

export default function usePagination<Document extends PageClientSide | SuperHubDocumentData>(
  doc?: Document,
  sidebar?: PaginationSidebar<Document>[],
) {
  const { sidebar: metaSidebar } = useContext(AppMetaContext) as AppMetaContextValue & {
    sidebar?: PaginationSidebar<Document>[];
  };

  const pages = (sidebar || metaSidebar || []).reduce<PaginationPage<Document>[]>((result, category) => {
    const a = flattenPageList(category.pages) as PaginationPage<Document>[];
    result.push(...a);
    return result;
  }, []);

  const key = !!doc && '_id' in doc ? '_id' : 'slug';
  const docIndex = pages.findIndex(page => page?.[key] === doc?.[key]);

  const getDocAtIndex = (index: number) => {
    if (index !== undefined && index >= 0 && index !== pages.length) return pages[index];
    return undefined;
  };
  let prev = getDocAtIndex(docIndex - 1);
  const next = getDocAtIndex(docIndex + 1);

  if (prev?.isBodyEmpty) {
    prev = getDocAtIndex(docIndex - 2);
  }

  return { prev, next };
}
